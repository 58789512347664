<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-cell title="产品名称" :value="details.name" />
            <van-cell title="规格/托盘号" :value="details.specifications" />
            <van-cell title="订单号/零件号" :value="details.batch_number" />
            <van-cell title="客户名称" :value="details.client_name" />
            <van-cell title="库存重量" :value="details|div_weight"><template #right-icon><span>KG</span></template></van-cell>
            <van-cell title="库存托盘数" :value="details.tray_num"><template #right-icon><span>个</span></template></van-cell>
            <van-cell title="入库单号" :value="details.with_entry_bill_name" />
            <van-cell title="入库日期" :value="details.with_entry_bill_date" />
            <van-cell title="提单号/供应商" :value="details.bill_num" />
            <van-cell title="所在公司" :value="details.enterprise_name" />
            <van-cell title="所在位置" :value="details|div_weizhi" />
        </van-cell-group>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { stock_details_request } from '@/network/WareHouse/stock'

import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name:'StockDetails',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            my_nav_bar_name: '库存详情',
            details: {},
            id: 0
        }
    },
    computed:{},
    methods:{
        get_stock_details() {
            this.$store.commit('true_loading')
            stock_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{
        div_weight(v) {
            return thousandBitSeparator(fomatFloat(v.weight))
        },
        div_weizhi(v) {
            return v.storeroom_name+'-'+v.localtion_name
        }
    },
    props:{},
    created(){
        this.id = this.$route.query.id;
        this.get_stock_details()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent
    },
    watch:{}
}
</script>

<style>
</style>